<template>
  <div>
    <b-card title="Detail Job Order">
      <b-row>
        <b-col cols="12" style="margin-bottom: 15px;">
          <b-button variant="primary" @click="goBack()">
            Back Job Order
          </b-button>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col md="6" sm="12">
          <b-row>
            <b-col cols="6">
              <b>Job Order Number :</b>
            </b-col>
            <b-col cols="6">
              {{ form.orderNumber}}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              Type :
            </b-col>
            <b-col cols="6">
              {{ form.props.type }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              Created At:
            </b-col>
            <b-col cols="6">
              {{ handleDate(form.createdAt) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              Updated At :
            </b-col>
            <b-col cols="6">
              {{ handleDate(form.updatedAt) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              Job Start : 
            </b-col>
            <b-col cols="6">
              {{ handleDate(form.props.JS) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              Status Job : 
            </b-col>
            <b-col cols="6">
              {{ form.props.status }} ( {{ handleStatus(form.props.status) }} )
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6" sm="12">
          <b-row>
            <b-col cols="6">
              Reason : 
            </b-col>
            <b-col cols="6">
              {{ handleError(form.props.reason) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              Job End : 
            </b-col>
            <b-col cols="6">
              {{ handleDate(form.props.JE) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              Mama Drum Picking Start : 
            </b-col>
            <b-col cols="6">
              {{ handleDate(form.props.MDS)}}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              Mama Drum Picking End : 
            </b-col>
            <b-col cols="6">
              {{ handleDate(form.props.MDE) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              Mama Drum Setup Start : 
            </b-col>
            <b-col cols="6">
              {{ handleDate(form.props.MSS) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              Mama Drum Setup End : 
            </b-col>
            <b-col cols="6">
              {{ handleDate(form.props.MSE) }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- <b-row style="margin-bottom:10px" class="show-on-mobile">
        <b-col>
          <b-form-checkbox
            v-model="stackedStatus"
            value="md"
            unchecked-value="false"
          >
            Stacked Table
          </b-form-checkbox>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col cols="12" style="margin-top:10px">
          <b-table
          :items="form.item"
          :fields="fields"
          responsive="sm"
          hover
          head-variant="dark"
          outlined
          small
          :stacked="stackedStatus"
          >
          <template #cell(actions)="row">
            <b-row>
              <b-col>
                <b-button
                  variant="success"
                  size="sm"
                  @click="detailItemPicklist(row.index)"
                  style="margin-right:10px">
                  <feather-icon size="1x" icon="EyeIcon"/>
                </b-button>
              </b-col>
            </b-row>
          </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>

    <!-- modal to show detail data of picklist -->
    <b-modal 
      v-model="modalPickList"
      title="Detail Picklist"
      size="lg"
      ok-only
      hide-header-close
      >
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Pick List Number :</b>
        </b-col>
        <b-col cols="6">
          {{ handleError(itemPicklist.picklistNo) }}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Pick List Line :</b>
        </b-col>
        <b-col cols="6">
          {{ handleError(itemPicklist.picklistLine) }}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Pick List Type :</b>
        </b-col>
        <b-col cols="6">
          {{ handleError(itemPicklist.pickListType) }}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Status Number :</b>
        </b-col>
        <b-col cols="6">
          {{ handleError(itemPicklistProps.statusNo) }}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Pick List Line Start :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.PLS)}}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Pick List Line End :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.PLE) }}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Baby Drum Picking Start :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.BDS)}}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Baby Drum Picking End :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.BDE)}}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Baby Drum Setup Start :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.BSS)}}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Baby Drum Setup End :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.BSE)}}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Run Time Start :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.RS)}}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Run Time End :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.RE)}}
        </b-col>
      </b-row>
      
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Pack Start :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.PS) }}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Pack End :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.PE) }}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Stop Time Start :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.STS) }}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Stop Time End :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.STE) }}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Delivery Packing Start :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.DPS) }}
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 10px;">
        <b-col cols="6">
          <b>Delivery Packing End :</b>
        </b-col>
        <b-col cols="6">
          {{ handleDate(itemPicklistProps.DPE) }}
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { dateFormat } from '@/utils/utils.js';
export default ({
  props:['id'],
  data() {
    return {
      fields:[
      { key: "pickList.lineItem", label: "Line Item", filterable: true, sortable: true },
      { key: "pickList.fromBatch", label: "From Batch", filterable: true, sortable: true },
      { key: "orderNumber", label: "Pick List", filterable: true, sortable: true },
      { key: "quantity", label: "Quantity", filterable: true, sortable: true },
      { key: "actions", label: "Actions" },
      ],
      form:{
        props:{}
      },
      item:[],
      itemPicklist:{},
      itemPicklistProps:{},
      modalPickList: false,
      stackedStatus: "md",
    }
  },
  methods: {
    goBack() {
      window.history.back()
    },
    detailItemPicklist(index) {
      this.modalPickList = true
      this.itemPicklist = this.form.item[index].pickList
      this.itemPicklistProps = this.form.item[index].pickList.props
    },
    handleDate(date) {
      if (date) {
        return dateFormat(date)
      } else {
        return "no date"
      }
    },
    handleError(data) {
      if (!data) {
        return "no data"
      } else {
        return data
      }
    },
    handleStatus(data) {
      switch(data) {
        case 10:
          return 'Created';
        case 15:
          return 'Can Pick Drum';
        case 20:
          return 'Job Type Confirm';
        case 25:
          return 'Picking Drum Ended';
        case 30:
          return 'Machine Selected';
        case 60:
          return 'Finished';
      }
    }
  },
  computed: {
  },
  mounted() {
    this.$store.dispatch('joborder/getJobOrderId', {id:this.id})
    .then((res)=>{
      this.form = res
      this.item = this.form.item
    }).catch((err) => {
      console.log(err);
    })
  }
})
</script>

<style>
@media (min-width: 761px) {
  .show-on-mobile {
    display: none !important;
  }
}
</style>
